@tailwind base;
@tailwind components;
@tailwind utilities;


label,li,p{
  font-size:14px !important; 
}


.hoverMenu:hover .hoverMenuList {
  display: inline-block;
  position: absolute;
  z-index: 100;
  top: 25px;
  left:0px;
  min-width: 200px;
  background-color: black;
  border-top:1px solid #ffffff;
}

.hoverMenuList li{
    padding:10px 20px;
    border-top:1px dotted #ffffff;
    font-size: 14px !important;
}


.hoverMenuList {
  display: none;
}
